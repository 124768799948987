import React from 'react'
import { Link, graphql } from 'gatsby'

import BlogNotice from '../components/blogNotice.js'
import Layout from '../components/layout'
import Seo from '../components/seo' // Imported JSX component SEO must be in PascalCase

import '../styles/blogPost.scss'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  return (
    <Layout location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className='blogPost'>
        <article>
          <header>
            <h1>
              {post.frontmatter.title}
            </h1>
            {post.frontmatter.date && (
              <p>
                {post.frontmatter.date}
              </p>
            )}
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          {/* <footer>
            <Bio />
          </footer> */}
        </article>

        <nav>
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              padding: 0
            }}
          >
            <li>
              {previous && (
                <Link to={previous.frontmatter.route} rel='prev'>
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.frontmatter.route} rel='next'>
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
        <BlogNotice />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
