import React from 'react'

const BlogNotice = () => {
  return (
    <div class='section cta card'>
      <h2>Do you have a minute?</h2>
      <p class='subtitle'>Nucleus provides analytics and bug-tracking for your desktop apps so you can follow and understand your users.</p>
      {/** Using target="_blank" without rel="noreferrer" is a security risk */}
      <a class='primary btn' target='_blank' rel='noreferrer' href='https://dash.nucleus.sh/analytics?demo=1'>Live Demo →</a><br />
      <p class='notice'>No sign up required.</p>
    </div>
  )
}

export default BlogNotice
